.table-container {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 20px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 1em;
  font-family: "Arial", sans-serif;
  text-align: left;
  box-shadow: 0 8px 16px rgba(0, 66, 153, 0.2);
}

.styled-table thead tr {
  background-color: #3498db;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

/* Campo de busca */
.search-section {
  margin-top: 20px;
}

.search-section input {
  width: 80%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content .btn {
  margin-right: 10px;
}

.cancel-btn {
  background-color: red;
  color: white;
}

select {
  width: 300px;
  height: 40px;
  font-size: 16px;
}
