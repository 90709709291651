.quiz-container {
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 66, 153, 0.479);
  border-radius: 15px;
  text-align: center;
  z-index: 2;
  position: relative;
  margin: 0 auto;
}

.title {
  font-size: 16px;
  line-height: 1.4rem;
  margin-bottom: 20px;
  text-align: justify;
}

.tempo-total {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: rgb(218, 218, 218);
  padding: 10px;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  width: 70px;
}

h2 {
  font-size: 18px;
  text-align: justify;
  margin-bottom: 15px;
}

.options {
  text-align: left;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

.inputradio {
  margin-right: 10px;
  transform: scale(1.5);
}

.btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.btn:hover {
  background-color: #218838;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
