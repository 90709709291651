body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://learngli.com.br/img/background.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
}

.container {
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 66, 153, 0.479);
  border-radius: 15px;
  text-align: center;
  z-index: 2;
  position: relative;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column-reverse;
}

.header h3 {
  margin: 0 auto;
}

#emailSection {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 25px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 66, 153, 0.479);
}

@media (max-width: 768px) {
  .container {
    max-width: 90%;
    padding: 20px;
    margin: 10px;
  }

  #emailSection {
    max-width: 100%;
    padding: 20px 0px 20px 0px;
    margin: 0px;
  }

  h1 {
    font-size: 1.8em;
  }

  input[type="email"],
  input[type="text"],
  input[type="password"] {
    padding: 12px;
    font-size: 1em;
  }

  button.btn {
    padding: 12px 20px;
    font-size: 1em;
  }

  #emailError {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 90%;
    padding: 20px;
  }

  .instruction-header h1 {
    font-size: 1.5em;
  }

  .instruction-header em,
  .suggestions h3,
  .instruction-list li,
  .suggestions li {
    font-size: 1em;
  }

  .start-btn {
    font-size: 1em;
    padding: 10px 20px;
  }
}

.instruction-header {
  margin-bottom: 20px;
}

.instruction-header h1 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #3498db;
}

.instruction-header em {
  color: #555;
}

.instruction-list {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.instruction-list li {
  font-size: 1em;
  margin-bottom: 20px;
  color: #333;
}

.suggestions {
  margin-top: 20px;
}

.suggestions h3 {
  font-size: 1.3em;
  color: #2c3e50;
  margin-bottom: 10px;
}

.suggestions ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.suggestions li {
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #555;
}

.instruction-footer {
  margin-top: 25px;
}

.btn {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #2980b9;
}

.start-btn {
  font-size: 1.1em;
  padding: 15px 20px;
  background-color: #2ecc71;
}

.start-btn:hover {
  background-color: #27ae60;
}

input[type="email"],
input[type="text"],
input[type="password"] {
  width: 80%;
  padding: 12px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

#timer {
  margin-top: 20px;
  font-size: 1.2em;
  color: #333;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#completionSection h2 {
  font-size: 1.8em;
}

#completionSection p {
  font-size: 1.2em;
  color: #2c3e50;
}

.logout-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: auto;
}

.logout-btn:hover {
  background-color: #ff1a1a;
}

/* Estilos para a tabela */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 1em;
  font-family: "Arial", sans-serif;
  text-align: left;
  box-shadow: 0 8px 16px rgba(0, 66, 153, 0.2);
}

.styled-table thead tr {
  background-color: #3498db;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #3498db;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
